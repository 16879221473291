import React from "react";

import Layout from "../components/Layout";

const Page = () => {
  return (
    <Layout>
      <section className="pt-12 md:pt-16 pb-22 md:pb-32">
        <div className="container">
          <h1>Terms and Conditions</h1>

          <p>
            Welcome to our website. Flavor Chef Catering is a California
            professional corporation that provides legal services in the area of
            estate and business planning. These Terms and Conditions of Use
            (“Agreement”) apply to any use of and access to our website,
          </p>
          <a href="https://flavorchefcatering.com">
            <p>https://flavorchefcatering.com</p>
          </a>
          <p>
            (“the website”) by you and/or your agents (“you”).  By using the
            website, you agree to comply with and be bound by the following
            Terms and Conditions of Use. Please review them carefully. If you do
            not agree to these terms and conditions, you should not use this
            website.
          </p>
          <ul>
            <li>
              <h3>Agreement.</h3>
              <p>
                This Agreement specifies and describes the terms and conditions
                applicable to your access of and use of the website. This
                Agreement may be modified at any time by Flavor Chef Catering
                upon posting of the modified Agreement. Any such modifications
                shall be effective immediately. You can view the most recent
                version of these terms at any time at
                https://flavorchefcatering.com. Each use by you shall constitute
                and be deemed your unconditional acceptance of this Agreement.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <h3>Privacy. </h3>
              <p>
                Your visit to our website is also governed by our Privacy
                Policy. Please review our Privacy Policy at
                https://flavorchefcatering.com. 
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <h3>Ownership. </h3>
              <p>
                All content included on this website is and shall continue to be
                the property of Flavor Chef Catering or its content suppliers
                and is protected under applicable copyright, patent, trademark,
                and other proprietary rights. Any copying, redistribution, use
                or publication by you of any such content or any part of the
                website is prohibited, except as expressly permitted in this
                Agreement. Under no circumstances will you acquire any ownership
                rights or other interest in any content by or through your use
                of this website.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <h3>Intended Audience. </h3>
              <p>
                This website is provided as a service to clients, colleagues,
                and others for general information only. This information is not
                designed to provide legal or other advice or to create an
                attorney-client relationship with Flavor Chef Catering.  You
                should not take, or refrain from taking, any action based on its
                content. Unsolicited emails from non-clients do not establish an
                attorney-client relationship. This website is intended for
                adults only. This website is not intended for any children under
                the age of 13.  
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <h3>Trademarks. </h3>
              <p>
                Flavor Chef Catering and others are either trademarks or
                registered trademarks of Flavor Chef Catering. Other product and
                company names mentioned on this Site may be trademarks of their
                respective owners. Unauthorized use of any of Flavor Chef
                Catering’s trademarks, service marks, or logos is prohibited,
                and may be a violation of federal or state trademark laws. 
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <h3>Website Use. </h3>
              <p>
                Flavor Chef Catering grants you a limited, revocable,
                nonexclusive license to use this website solely for your own
                personal use and not for republication, distribution,
                assignment, sublicense, sale, preparation of derivative works,
                or other use. You agree not to copy materials on the site,
                reverse engineer or break into the site, or use materials,
                products or services in violation of any law. The use of this
                website is at the discretion of Flavor Chef Catering and Flavor
                Chef Catering may terminate your use of this website at any
                time.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <h3>Links to our Website. </h3>
              <p>
                Unless otherwise set forth in a written agreement between you
                and Flavor Chef Catering, if you link to our website, you must
                adhere to the following linking policy: (1) the appearance,
                position and other aspects of the link may not be such as to
                damage or dilute the goodwill associated with Flavor Chef
                Catering’s name and trademarks; (2) The appearance, position,
                and other attributes of the link may not create the false
                appearance that your organization or entity is sponsored by,
                affiliated with, or associated with Flavor Chef Catering; (3)
                when selected by a user, the link must display the website on
                full-screen and not within a “frame” on the linking website; and
                (4) Flavor Chef Catering reserves the right to revoke its
                consent to the link at any time in its sole discretion. 
              </p>
            </li>
          </ul>
          <ol>
            <li>
              <ol>
                <li>
                  <h3>Links to Other Sites. </h3>
                  <p>
                    The website may contain links to websites operated by third
                    parties, including but not limited to Docubank (
                    <a href="http://www.docubank.com">www.docubank.com</a>
                    ); Facebook (
                    <a href="http://www.facebook.com">www.facebook.com</a>) and
                    Instagram (
                    <a href="http://www.instagram.com">www.instagram.com</a>
                    ). Flavor Chef Catering provides these links as a
                    convenience, and use of these sites is at your own risk. The
                    linked sites are not under the control of Flavor Chef
                    Catering, and Flavor Chef Catering is not responsible for
                    the content available on the other sites.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <ul>
            <li>
              <h3>Compliance with Laws. </h3>
              <p>
                You agree to comply with all applicable laws regarding your use
                of the website. You further agreed that information provided by
                you is truthful and accurate to the best of your knowledge.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <h3>Indemnification. </h3>
              <p>
                You agree to indemnify, defend and hold Flavor Chef Catering and
                our owners, employees, and affiliates, harmless from any
                liability, loss, claim and expense, including reasonable
                attorney's fees, related to your violation of this Agreement or
                use of the website.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <h3>Disclaimer. </h3>
              <p>
                THE INFORMATION ON THIS WEBSITE IS PROVIDED ON AN "AS IS," "AS
                AVAILABLE" BASIS AND IS INTENDED TO BE A GENERAL INFORMATION
                RESOURCE ONLY. YOU AGREE THAT USE OF THIS WEBSITE IS AT YOUR
                SOLE RISK. Flavor Chef Catering DISCLAIMS ALL WARRANTIES OF ANY
                KIND, INCLUDING BUT NOT LIMITED TO ANY EXPRESS WARRANTIES,
                STATUTORY WARRANTIES, AND ANY IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                NON-INFRINGEMENT. TO THE EXTENT YOUR JURISDICTION DOES NOT ALLOW
                LIMITATIONS ON WARRANTIES, THIS LIMITATION MAY NOT APPLY TO YOU.
                YOUR SOLE AND EXCLUSIVE REMEDY RELATING TO YOUR USE OF THE
                WEBSITE SHALL BE TO DISCONTINUE USING THE WEBSITE.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <h3>Limitation of Liability. </h3>
              <p>
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, UNDER NO CIRCUMSTANCES
                WILL Flavor Chef Catering BE LIABLE OR RESPONSIBLE FOR ANY
                DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL (INCLUDING DAMAGES
                FROM LOSS OF BUSINESS, LOST PROFITS, LITIGATION, OR THE LIKE),
                SPECIAL, EXEMPLARY, PUNITIVE, OR OTHER DAMAGES, UNDER ANY LEGAL
                THEORY, ARISING OUT OF OR IN ANY WAY RELATING TO THE WEBSITE,
                YOUR WEBSITE USE, OR THE CONTENT, EVEN IF ADVISED OF THE
                POSSIBILITY OF SUCH DAMAGES. YOUR SOLE REMEDY FOR
                DISSATISFACTION WITH THE WEBSITE AND/OR CONTENT IS TO CEASE ALL
                OF YOUR WEBSITE USE.
              </p>
            </li>
          </ul>
          <p>
            You may have additional rights under certain laws (including
            consumer laws) which do not allow the exclusion of implied
            warranties, or the exclusion or limitation of certain damages. If
            these laws apply to you, the exclusions or limitations in this
            Agreement that directly conflict with such laws may not apply to
            you.
          </p>
          <ul>
            <li>
              <h3>Use of Information. </h3>
              <p>
                Flavor Chef Catering reserves the right, and you authorize us,
                to use and assign all information regarding website uses by you
                and all information provided by you in any manner consistent
                with our Privacy Policy.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <h3>Copyrights and Copyright Agent. </h3>
              <p>
                If you believe your work has been copied in a way that
                constitutes copyright infringement, or your intellectual
                property rights have otherwise been violated, please provide a
                notice containing all of the following information to our
                Copyright Agent:
              </p>
            </li>
          </ul>
          <ol>
            <li>
              <ol>
                <li>
                  <p>
                    An electronic or physical signature of the person authorized
                    to act on behalf of the owner of the copyright or other
                    intellectual property interest;
                  </p>
                </li>
                <li>
                  <p>
                    A description of the copyrighted work that you claim has
                    been infringed;
                  </p>
                </li>
                <li>
                  <p>
                    A description of where the material that you claim is
                    infringing is located on the Site;
                  </p>
                </li>
                <li>
                  <p>Your address, telephone number, and e-mail address;</p>
                </li>
                <li>
                  <p>
                    A statement by you that you have a good faith belief that
                    the disputed use is not authorized by the copyright owner,
                    its agent, or the law; and
                  </p>
                </li>
                <li>
                  <p>
                    A statement by you, made under penalty of perjury, that the
                    above information in your notice is accurate and that you
                    are the copyright owner or authorized to act on the
                    copyright owner's behalf.
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <p>
            Our Copyright Agent for Notice of claims of copyright infringement
            on the Site can be reached as follows:
          </p>

          <p>By Mail: 1175 Park Center Dr, Vista, CA 92081</p>

          <p>By Phone: (619)-916-8360</p>

          <p>By E-mail: flavorchefcatering@gmail.com</p>
          <ul>
            <li>
              <h3>Applicable Law. </h3>
              <p>
                You agree that the laws of the state of California, without
                regard to conflicts of laws provisions will govern these Terms
                and Condition of Use and any dispute that may arise between you
                and Flavor Chef Catering or its affiliates.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <h3>Arbitration. </h3>
              <p>
                ALL DISPUTES ARISING OUT OF OR RELATED TO THIS AGREEMENT OR ANY
                ASPECT OF THE RELATIONSHIP BETWEEN YOU AND THE FIRM, WHETHER
                BASED IN CONTRACT, TORT, STATUTE, FRAUD, MISREPRESENTATION OR
                ANY OTHER LEGAL THEORY, WILL BE RESOLVED THROUGH FINAL AND
                BINDING ARBITRATION BEFORE A NEUTRAL ARBITRATOR INSTEAD OF IN A
                COURT BY A JUDGE OR JURY.  YOU AND THE FIRM HEREBY WAIVE THE
                RIGHT TO TRIAL BY A JURY.  YOU AGREE THAT ANY ARBITRATION UNDER
                THIS AGREEMENT WILL TAKE PLACE ON AN INDIVIDUAL BASIS, AND THAT
                CLASS ARBITRATIONS AND CLASS ACTIONS ARE NOT PERMITTED.  YOU ARE
                EXPRESSLY AGREEING TO GIVE UP THE ABILITY TO PARTICIPATE IN A
                CLASS ACTION. The arbitration will be administered by the
                American Arbitration Association under its Commercial
                Arbitration Rules and Mediation Procedures.  Any arbitration
                hearing will be held in San Francisco County, California. The
                applicable governing law will be California law (provided that
                with respect to arbitrability issues, federal arbitration law
                will govern). The arbitrator’s decision will follow the terms of
                this Agreement and will be final and binding. The arbitrator
                will have authority to award temporary, interim or permanent
                injunctive relief or relief providing for specific performance
                of this Agreement, but only to the extent necessary to provide
                relief warranted by the individual claim before the arbitrator.
                The award rendered by the arbitrator may be confirmed and
                enforced in any court having jurisdiction thereof.  This clause
                shall not preclude the parties from seeking provisional remedies
                in aid of arbitration from a court of appropriate jurisdiction.
              </p>
            </li>
          </ul>
          <p>
            Attorney’s Fees and Costs.   In any arbitration proceeding to
            enforce rights under this Agreement, the prevailing party will be
            entitled to recover costs and attorneys’ fees.
          </p>

          <p>
            Opt-out of Agreement to Arbitrate.  You can decline this agreement
            to arbitrate by contacting flavorchefcatering@gmail.com within 30
            days of first accepting these Terms and Conditions of Use and/or
            accessing our Website, whichever is earlier, and stating that you
            (including your first and last name) decline this arbitration
            agreement.
          </p>
          <ul>
            <li>
              <h3>Severability. </h3>
              <p>
                If any provision of this Agreement shall be adjudged by any
                court of competent jurisdiction to be unenforceable or invalid,
                that provision shall be limited or eliminated to the minimum
                extent necessary so that this Agreement will otherwise remain in
                full force and effect.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <h3>Waiver.</h3>
              <p>
                The failure of Flavor Chef Catering exercise or enforce any
                right or provision of this Agreement shall not operate as a
                waiver of such right or provision.
              </p>
              <p>
                Any waiver of this Agreement by Flavor Chef Catering must be in
                writing and signed by an authorized representative of Flavor
                Chef Catering. 
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <h3>Termination. </h3>
              <p>
                Flavor Chef Catering may terminate this Agreement at any time,
                with or without notice, for any reason.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <h3>Relationship of the Parties. </h3>
              <p>
                Nothing contained in this Agreement or your use of the website
                shall be construed to constitute either party as a partner,
                joint venturer, employee or agent of the other party, nor shall
                either party hold itself out as such.
              </p>
              <p>
                Neither party has any right or authority to incur, assume or
                create, in writing or otherwise, any warranty, liability or
                other obligation of any kind, express or implied, in the name of
                or on behalf of the other party, it being intended by both
                parties that each shall remain independent contractors
                responsible for its own actions.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <h3>Entire Agreement.</h3>
              <p>
                This Terms and Conditions of Use constitutes the entire
                agreement between you and Flavor Chef Catering and governs the
                terms and conditions of your use of the website, and supersedes
                all prior or contemporaneous communications and proposals,
                whether electronic, oral or written, between you and Flavor Chef
                Catering with respect to this website.
              </p>
              <p>
                Notwithstanding the foregoing, you may also be subject to
                additional terms and conditions, posted policies (including but
                not limited to the Privacy Policy), guidelines, or rules that
                may apply when you use the website.
              </p>
              <p>
                Flavor Chef Catering may revise this Terms and Conditions of Use
                at any time by updating this Agreement and posting it on the
                website.
              </p>
              <p>
                Accordingly, you should visit the website and review the Terms
                and Conditions of Use periodically to determine if any changes
                have been made. Your continued use of this website after any
                changes have been made to the Terms and Conditions of Use
                signifies and confirms your acceptance of any such changes or
                amendments to the Terms and Conditions of Use.
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <h3>Contact Information</h3>
            </li>
          </ul>
          <p>flavorchefcatering@gmail.com</p>
        </div>
      </section>
    </Layout>
  );
};

export default Page;
